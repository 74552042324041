/* YourComponent.module.css */
.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoPlayer {
  max-width: 100%;
  height: auto;
}