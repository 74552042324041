.guessInfo{
  z-index: 0;
  width: 100%;
  min-height: 60px;
  height: fit-content;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px 10px 0px;
  font-size: 16pt;
  
}

.guessNumber{
  color: white;
  font-size: 14pt;
  margin-top: 5px;
  margin-bottom: 0px;
}

.guessName{
  color: white;
  font-size: 16pt;
  margin-top: 5px;
  margin-bottom: 0px;
  width: 320px;
}
