.blurBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  /* Adjust the blur amount as needed */
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.endGame{
  position: fixed;
  width: 340px;
  height: 420px;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #9b9fb5;
}

.closeEndGame{
  background-color: transparent;
  color: black;
  outline: none;
  border: none;
  margin-left: 300px;
  font-size: 12pt;
  cursor: pointer;
  margin-top: 10px;
}

.endGame p{
  color: black;
  font-size: 16pt;
  margin-top: 10px;
  margin-bottom: 20px;
}