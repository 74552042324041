.blurBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  /* Adjust the blur amount as needed */
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container{
  position: fixed;
  width: 340px;
  height: 425px;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  border-radius: 20px;
  background-color: #9b9fb5;
}

.closeButton{
  background-color: transparent;
  color: black;
  outline: none;
  border: none;
  font-size: 12pt;
  cursor: pointer;
  margin-left: 290px;
}

h3{
  margin: 0 0 0 0;
  color: black;
}