.blurBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  /* Adjust the blur amount as needed */
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}


.container{
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 340px;
  height: 365px;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  border-radius: 10px;
  background-color: #9b9fb5;
  font-family: 'JapaneseFont', sans-serif;
  font-size: 12pt;
}

.closeButton{
  background-color: transparent;
  color: black;
  outline: none;
  border: none;
  margin-left: 310px;
  font-size: 14pt;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 15px;
}

.row {
  display: flex;
  text-align: left;
  align-items: center;
  margin: 0 20px 0 20px;
}

.rowText{
  margin-left: 20px;
  color: black;
  margin-top: 10px;
  width: 250px;
  margin-bottom: 10px;
}

a{
  color: black;
  text-decoration: none;
}